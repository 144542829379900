.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fdfdfd;
}
.navbar,header{
    font-weight: bold;
    padding-right: 10px;
    font-size: 1.2rem;
    color: #1a2267;
}
.nav-button {
    background-color: #1a2267;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.5rem;
    margin: 0.2rem;
    cursor: pointer;
    border-radius: 4px;
}

.nav-button:hover {
    background-color: #f47525;
}